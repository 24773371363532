import { Rdl as ARJS } from "@mescius/activereportsjs/core";
import { ParameterValueMap, AllReportParametersData } from "./types";
import { componentMap } from "./AllReportParameters";

const setParameterValue = (
  parameter: ARJS.ReportParameter,
  values: (string | boolean)[]
): ARJS.ReportParameter => {
  const stringValues = values.map((value) => {
    // If value undefined or empty string, set DefaultValue to "null" to avoid errors in ActiveReports when parameter is used in query
    if (value === undefined || value === "") {
      return "null";
    }
    // ActiveReports uses "True" and "False" as string values for boolean parameters
    if (typeof value === "boolean") {
      return value ? "True" : "False";
    }
    return value;
  });
  return {
    ...parameter,
    DefaultValue: { Values: stringValues },
  };
};

export const setParameterValues = (
  parameters: ARJS.ReportParameter[],
  values: ParameterValueMap
) =>
  parameters
    .map((parameter) => {
      if (parameter !== undefined) {
        return setParameterValue(
          parameter,
          values[parameter.Name] || parameter.DefaultValue?.Values
        );
      }
    })
    .filter((parameter): parameter is ARJS.ReportParameter => !!parameter);

export const getParameterValuesFromFormData = (
  formData: AllReportParametersData
): ParameterValueMap => {
  const parameterKeys = Object.keys(componentMap);
  const formKeys = Object.keys(formData);

  return parameterKeys
    .filter((parameterKey) =>
      formKeys.some((formDataKey) => formDataKey === parameterKey)
    )
    .reduce((acc, parameterKey) => {
      const value = formData[parameterKey as keyof AllReportParametersData];
      let values = Array.isArray(value)
        ? value
            .map((item) => {
              if (typeof item === "object") {
                return item.value;
              }
              return item;
            })
            // Join values into a single string because ActiveReports uses only one value when parameter is used in query
            .join(",")
        : value;
      // Replace % with * to match the wildcard character in REST API queries
      if (typeof values === "string") {
        values = values.replace("%", "*");
      }
      // Values are returned as an array to match the structure of the DefaultValue property in ARJS.ReportParameter
      return { ...acc, [parameterKey]: [values] };
    }, {});
};
