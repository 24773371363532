import { useCallback, useMemo, useRef } from "react";
import {
  Button,
  Icon,
  IconAddPlusV1,
  IconCheck24Px,
  IconCloseCrossV1,
  IconEditV1,
} from "@tocoman/ui";
import { useTranslation } from "react-i18next";
import {
  AllOrganizationData,
  OrganizationInformation,
} from "../Modals/OrganizationInformation";
import { useModalData } from "../../../hooks/useModalData";
import {
  ColDef,
  ICellRendererParams,
  ISetFilterParams,
  StatusPanelDef,
  ValueFormatterParams,
} from "ag-grid-community";
import { Table } from "src/client-ts/components/Table";
import { AgGridReact } from "ag-grid-react";
import { useSelectRowsAsync } from "../../../components/Table/useSelectRowsAsync";
import { useGetOrganizations } from "./useSuperAdminOrganizations";
import { OrgWithAdminetTenants } from "../../../../server-ts/super-admin/organizations/SuperAdminOrganizationQueries";
import { AdminUserTableRow } from "../../../../../ts-bindings/AdminUserTableRow";

export type OrganizationRow = {
  organization: string;
  cognitoIdentityProviderName: string | null;
  costControlUsers: number;
  costControlRWUsers: number;
  estimationUsers: number;
  estimationRWUsers: number;
  SSO: boolean;
  plan: string;
  adminetTenants: Array<{
    tenantId: string;
    tenantName: string | null;
  }>;
  priceListUpdate: boolean;
  companyName: string | null;
  description: string | null;
  notes: string | null;
  sendInvitation: boolean;
  ultimaInstanceType: string;
  expiresAt: Date | null;
  defaultVAT: number;
  integrationEnabled: boolean;
};

export const OrganizationsTab = () => {
  const { t } = useTranslation("superAdmin");
  const organizations:
    | OrgWithAdminetTenants[]
    | undefined = useGetOrganizations();

  const organizationsData: OrganizationRow[] =
    organizations?.map((org) => ({
      organization: org.name,
      cognitoIdentityProviderName: org.cognitoIdentityProviderName,
      costControlUsers: org.costControlUserQuota,
      costControlRWUsers: org.costControlRWUserQuota,
      estimationUsers: org.estimationUserQuota,
      estimationRWUsers: org.estimationRWUserQuota,
      SSO:
        org.cognitoIdentityProviderName !== "" &&
        org.cognitoIdentityProviderName !== null,
      plan: org.plan,
      priceListUpdate: org.priceListUpdate,
      adminetTenants: org.adminetTenants,
      companyName: org.companyName,
      description: org.description,
      notes: org.notes,
      sendInvitation: org.sendInvitation,
      ultimaInstanceType: org.ultimaInstanceType,
      expiresAt: org.expiresAt,
      defaultVAT: org.defaultVAT,
      integrationEnabled: org.integrationEnabled,
    })) ?? [];

  const [
    editOrganizationOpen,
    editOrganization,
    setEditOrganization,
  ] = useModalData<OrganizationRow>();

  const gridRef = useRef<AgGridReact<OrganizationRow>>(null);
  const selectRowsAsync = useSelectRowsAsync(gridRef?.current?.api);

  const newOrg: OrganizationRow = useMemo(() => {
    return {
      organization: "",
      cognitoIdentityProviderName: null,
      costControlRWUsers: 0,
      estimationRWUsers: 0,
      estimationUsers: 0,
      costControlUsers: 0,
      SSO: false,
      plan: "premium",
      priceListUpdate: true,
      adminetTenants: [],
      companyName: null,
      description: null,
      notes: null,
      sendInvitation: true,
      ultimaInstanceType: "prod",
      expiresAt: null,
      defaultVAT: 25.5,
      integrationEnabled: false,
    };
  }, []);

  const booleanFilterFormatter = useCallback(
    (params: ValueFormatterParams<AdminUserTableRow>) => {
      return params.value === true ? t`yes` : t`no`;
    },
    [t]
  );

  const columnDefs: ColDef<OrganizationRow>[] = useMemo(
    () => [
      {
        field: "organization",
        headerName: t`organization`,
        enableRowGroup: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      {
        field: "costControlUsers",
        headerName: t`ccUsers`,
        enableRowGroup: true,
        filter: "agNumberColumnFilter",
      },
      {
        field: "costControlRWUsers",
        headerName: t`ccWriteUsers`,
        enableRowGroup: true,
        filter: "agNumberColumnFilter",
      },
      {
        field: "estimationUsers",
        headerName: t`ceUsers`,
        enableRowGroup: true,
        filter: "agNumberColumnFilter",
      },
      {
        field: "estimationRWUsers",
        headerName: t`ceWriteUsers`,
        enableRowGroup: true,
        filter: "agNumberColumnFilter",
      },
      {
        field: "plan",
        headerName: t`plan`,
        enableRowGroup: true,
      },
      {
        field: "priceListUpdate",
        headerName: t`priceListUpdate`,
        enableRowGroup: true,
        filter: "agSetColumnFilter",
        filterParams: {
          valueFormatter: booleanFilterFormatter,
        } as ISetFilterParams,
        cellRenderer: (cellData: ICellRendererParams<OrganizationRow>) => {
          return (
            <div className={"flex h-full"}>
              {cellData.value ? (
                <Icon
                  icon={IconCheck24Px}
                  className={"text-primary self-center"}
                />
              ) : (
                <Icon icon={IconCloseCrossV1} className={"self-center"} />
              )}
            </div>
          );
        },
      },
      {
        field: "SSO",
        headerName: t`SSO`,
        filter: false,
        cellRenderer: (cellData: ICellRendererParams<OrganizationRow>) => {
          return (
            <div className={"flex h-full"}>
              {cellData.value ? (
                <Icon
                  icon={IconCheck24Px}
                  className={"text-primary self-center"}
                />
              ) : (
                <Icon icon={IconCloseCrossV1} className={"self-center"} />
              )}
            </div>
          );
        },
      },
      {
        field: "sendInvitation",
        headerName: t`sendInvitation`,
        enableRowGroup: true,
        initialHide: true,
        filter: "agSetColumnFilter",
        filterParams: {
          valueFormatter: booleanFilterFormatter,
        } as ISetFilterParams,
        cellRenderer: (cellData: ICellRendererParams<OrganizationRow>) => {
          return (
            <div className={"flex h-full"}>
              {cellData.value ? (
                <Icon
                  icon={IconCheck24Px}
                  className={"text-primary self-center"}
                />
              ) : (
                <Icon icon={IconCloseCrossV1} className={"self-center"} />
              )}
            </div>
          );
        },
      },
      {
        field: "notes",
        headerName: t`notes`,
        enableRowGroup: true,
      },
      {
        field: "expiresAt",
        headerName: t`expiresAt`,
        enableRowGroup: true,
        filter: "agDateColumnFilter",
        type: "isoDate",
      },
      {
        field: "integrationEnabled",
        headerName: t`integrationEnabled`,
        initialHide: true,
      },
      {
        colId: "edit",
        headerName: "",
        pinned: "right",
        width: 60,
        filter: false,
        sortable: false,
        lockVisible: true,
        lockPinned: true,
        lockPosition: true,
        resizable: false,
        cellRenderer: (cellData: ICellRendererParams<OrganizationRow>) => {
          return (
            <Button
              testId="editOrgBtn"
              icon={IconEditV1}
              variant="text"
              onClick={() => setEditOrganization(cellData.data ?? null)}
            />
          );
        },
      },
    ],
    [t, setEditOrganization]
  );

  const defaultColDef: ColDef<OrganizationRow> = useMemo(
    () => ({
      sortable: true,
      flex: 1,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
    }),
    []
  );

  const actions = useCallback(
    () => (
      <Button
        testId={"add-org-btn"}
        className={"my-3"}
        variant="text"
        label={t`addOrganization`}
        onClick={() => setEditOrganization(newOrg)}
        icon={IconAddPlusV1}
      />
    ),
    [newOrg, setEditOrganization, t]
  );

  const statusBar = useMemo<{ statusPanels: StatusPanelDef[] }>(() => {
    return {
      statusPanels: [
        {
          statusPanel: actions,
        },
      ],
    };
  }, [actions]);

  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: t`columns`,
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressPivotMode: true,
          suppressValues: true,
          suppressRowGroups: true,
        },
      },
    ],
  };

  return (
    <>
      <Table<OrganizationRow>
        gridRef={gridRef}
        className="w-full h-full"
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowData={organizationsData}
        rowGroupPanelShow={"always"}
        statusBar={statusBar}
        rowSelection={"multiple"}
        sideBar={sideBar}
        getRowId={({ data }) => data.organization}
      />
      {editOrganization && (
        <OrganizationInformation
          isOpen={editOrganizationOpen}
          setOpen={setEditOrganization}
          organizationInformation={editOrganization}
          onNewOrganization={(newOrg: AllOrganizationData) =>
            selectRowsAsync([newOrg.organization])
          }
        />
      )}
    </>
  );
};
