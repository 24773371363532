import { useState, useEffect } from "react";
import "@mescius/activereportsjs/styles/ar-js-designer.css";
import "@mescius/activereportsjs/styles/ar-js-ui.css";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Rdl as ARJS } from "@mescius/activereportsjs/core";
import { Core } from "@mescius/activereportsjs";

import { reportCustomCodes } from "./CustomFunctions";
import { Loader } from "@tocoman/ui";
import { reportError } from "src/client-ts/utils/error";
import {
  ARJSReportTemplateWithUser,
  useGetReportTemplateByName,
  useUpdateReportTemplate,
} from "./hooks/useReportTemplates";
import { ReportDesigner } from "./ReportDesigner";
import { setParameterValues } from "../../Estimation/Report/utils";
import { parameterDefaultValues } from "./parameterSchema";

Core.CustomCode.registerFunctions(reportCustomCodes);

export const ReportDesignerContainer = () => {
  const [activeReport, setActiveReport] = useState<
    ARJSReportTemplateWithUser | undefined
  >(undefined);
  const { t } = useTranslation("reports", {
    keyPrefix: "reportDesigner",
  });

  const { reportName } = useParams();

  useEffect(() => {
    if (!reportName) {
      reportError(t("reportNameMissing"), new Error(t("reportNameMissing")));
    }
  }, [reportName]);

  const { data: reportTemplate, isLoading } = useGetReportTemplateByName(
    reportName
  );
  const saveReportTemplate = useUpdateReportTemplate();

  useEffect(() => {
    if (reportTemplate) {
      setActiveReport(reportTemplate);
    }
  }, [reportTemplate]);

  const sanitizeReportTemplate = (template: ARJS.Report): ARJS.Report => {
    const sanitizedReportParameters = setParameterValues(
      template.ReportParameters || [],
      parameterDefaultValues
    );

    return {
      ...template,
      ReportParameters: sanitizedReportParameters,
    };
  };

  const updateReport = async (
    name: string,
    template: ARJS.Report
  ): Promise<number> => {
    setActiveReport({
      ...(activeReport as ARJSReportTemplateWithUser),
      template,
    });
    const { id } = await saveReportTemplate.mutateAsync({
      name,
      template: sanitizeReportTemplate(template),
    });

    return id;
  };

  return (
    <div className="w-full h-full flex pl-5">
      {isLoading && <Loader />}
      {!isLoading && activeReport && (
        <ReportDesigner report={activeReport} updateReport={updateReport} />
      )}
    </div>
  );
};
