import { Rdl as ARJS } from "@mescius/activereportsjs/core";
import { ComponentPropsMap } from "../../Estimation/Report/AllReportParameters";

export interface DataSetDefinition {
  Name: string;
  Query: ARJS.Query;
  CaseSensitivity?: ARJS.DataSet["CaseSensitivity"];
  KanatypeSensitivity?: ARJS.DataSet["KanatypeSensitivity"];
  AccentSensitivity?: ARJS.DataSet["AccentSensitivity"];
  WidthSensitivity?: ARJS.DataSet["WidthSensitivity"];
  Fields?: ARJS.Field[];
}

export interface DataSourceDefinition {
  url?: string;
  dataSets?: (DataSetDefinition & {
    Params?: Array<keyof ComponentPropsMap>;
  })[];
}

export const dataSourceDefinitions: Record<string, DataSourceDefinition> = {
  "rest-api": {
    url: `/api/`,
    dataSets: [],
  },
  Groups: {
    dataSets: [
      {
        Name: "Groups",
        Query: {
          DataSourceName: "rest-api",
          CommandText:
            '="uri=/v1/estima/projects/" & Parameters!projectId.Value & "/groups?limit=" & Parameters!limit.Value & ";jpath=$.results[*]"',
        },
      },
    ],
  },
  Locations: {
    dataSets: [
      {
        Name: "Locations",
        Query: {
          DataSourceName: "rest-api",
          CommandText:
            '="uri=/v1/estima/projects/" & Parameters!projectId.Value & "/locations?limit=" & Parameters!limit.Value & ";jpath=$.results[*]"',
        },
      },
    ],
  },
  Classifications: {
    dataSets: [
      {
        Name: "Classifications",
        Query: {
          DataSourceName: "rest-api",
          CommandText:
            '="uri=/v1/estima/projects/" & Parameters!projectId.Value & "/classifications?limit=" & Parameters!limit.Value & ";jpath=$.results[*]"',
        },
      },
    ],
  },
  WorkItems: {
    dataSets: [
      {
        Name: "WorkItems",
        Query: {
          DataSourceName: "rest-api",
          CommandText:
            '="uri=/v1/estima/projects/" & Parameters!projectId.Value & "/work-items?include=calculations,locations&limit=" & Parameters!limit.Value & ";jpath=$.results[*]"',
        },
        Params: [
          "subprojectId",
          "includeSocialExpenses",
          "workItemCodes",
          "buildingComponentCodes",
          "buildingComponentClasses",
        ],
      },
    ],
  },
  Resources: {
    dataSets: [
      {
        Name: "Resources",
        Query: {
          DataSourceName: "rest-api",
          CommandText:
            '="uri=/v1/estima/projects/" & Parameters!projectId.Value & "/resources?include=calculations&limit=" & Parameters!limit.Value & ";jpath=$.results[*]"',
        },
        Params: [
          "subprojectId",
          "includeSocialExpenses",
          "locationCodes",
          "workItemCodes",
          "buildingComponentCodes",
          "buildingComponentClasses",
        ],
      },
    ],
  },
};

export const getDataSetDefinitions = Object.keys(dataSourceDefinitions)
  .map((dataSourceName) =>
    dataSourceDefinitions[dataSourceName].dataSets?.map(({ Name }) => Name)
  )
  .flat();
