import { Rdl as ARJS } from "@mescius/activereportsjs/core";
import { componentMap, ComponentPropsMap } from "./AllReportParameters";

import { ParameterOption } from "./types";
import {
  useProjectDetailsQuery,
  useProjectLocationsQuery,
} from "./useRestApiProjectData";

interface ParameterFormProps {
  reportParameters: ARJS.ReportParameter[] | undefined;
  projectId: number;
  selectedParameters?: ParameterOption[];
}

export const ParameterForm = ({
  reportParameters,
  selectedParameters,
  projectId,
}: ParameterFormProps) => {
  const { data: projectData } = useProjectDetailsQuery(projectId);

  const subProjects = projectData?.subProjects.map(
    (subProject: { id: number; name: string; code: string }) => ({
      id: subProject.id,
      name: subProject.name,
      code: subProject.code,
    })
  );

  const { data: locationsData } = useProjectLocationsQuery(projectId);

  const locations = locationsData?.results.map(
    (location: { code: string }) => location.code
  );

  const renderComponent = (
    reportParameter: ARJS.ReportParameter | ParameterOption
  ) => {
    const parameterComponentName = Object.prototype.hasOwnProperty.call(
      reportParameter,
      "Name"
    )
      ? ((reportParameter as ARJS.ReportParameter)
          .Name as keyof ComponentPropsMap)
      : ((reportParameter as ParameterOption).key as keyof ComponentPropsMap);

    const Component = componentMap[parameterComponentName];
    const props = { subProjects, locations };

    return Component ? <Component {...props} /> : undefined;
  };

  const ParameterComponents = selectedParameters
    ? selectedParameters.map(renderComponent)
    : reportParameters
        ?.map(renderComponent)
        .filter((component) => component !== undefined);

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-col gap-3 p-3 w-full h-full border-light rounded border">
        {ParameterComponents}
      </div>
    </div>
  );
};
