import { useGetUsersAgGridConfiguration } from "./useUsersAgGridConfigurations";
import { useSaveUsersAgGridConfiguration } from "./useSaveUsersAgGridConfiguration";
import { useCallback, useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  ColumnPinnedEvent,
  ColumnResizedEvent,
  ColumnRowGroupChangedEvent,
  ColumnVisibleEvent,
  DragStoppedEvent,
  ToolPanelVisibleChangedEvent,
} from "ag-grid-community";

export function useUsersAgGridConfiguration(
  gridReady: boolean,
  gridRef: React.RefObject<AgGridReact>,
  agGridName: string,
  projectId?: number
) {
  const [toolPanel, setToolPanel] = useState<{
    panel: string | null;
    visible: boolean;
  }>({ panel: null, visible: false });

  const gridConfiguration = useGetUsersAgGridConfiguration(
    agGridName,
    projectId
  );
  const saveGridConfiguration = useSaveUsersAgGridConfiguration(
    gridRef,
    agGridName,
    projectId
  );

  const setGridConfiguration = useCallback(() => {
    if (
      gridReady &&
      gridRef.current &&
      gridConfiguration?.agGridConfiguration
    ) {
      const configuration = JSON.parse(gridConfiguration.agGridConfiguration);

      gridRef.current.api.applyColumnState({
        state: configuration,
        applyOrder: true,
      });
    }
  }, [gridConfiguration?.agGridConfiguration, gridReady, gridRef]);

  useEffect(() => {
    if (gridReady) {
      setGridConfiguration();
    }
  }, [gridReady, gridConfiguration, setGridConfiguration]);

  useEffect(() => {
    if (gridRef.current) {
      const api = gridRef.current.api;

      const suppressDefaultColDefOverride = (event: { source: string }) => {
        if (event.source === "gridOptionsUpdated") {
          setGridConfiguration();
        }
      };

      api?.addEventListener(
        "columnEverythingChanged",
        suppressDefaultColDefOverride
      );

      return () => {
        api?.removeEventListener(
          "columnEverythingChanged",
          suppressDefaultColDefOverride
        );
      };
    }
  }, [gridRef, setGridConfiguration]);

  const toolPanelVisibleChanged = (event: ToolPanelVisibleChangedEvent) => {
    if (toolPanel.visible !== event.visible) {
      setToolPanel({
        panel: event.api.getOpenedToolPanel(),
        visible: event.visible,
      });
    }
  };

  const toolPanelVisible = useCallback(() => {
    if (toolPanel?.visible && toolPanel?.panel) {
      gridRef.current?.api.openToolPanel(toolPanel.panel);
    }
  }, [gridRef, toolPanel]);

  useEffect(() => {
    toolPanelVisible();
  }, [saveGridConfiguration, toolPanelVisible]);

  const configEvents = setConfigEvents(
    saveGridConfiguration,
    toolPanelVisibleChanged
  );

  return {
    configEvents,
  };
}

// This function is used to set the events to AG Grid that will be used to save the users grid configuration
export function setConfigEvents(
  func: (
    event:
      | DragStoppedEvent
      | ColumnResizedEvent
      | ColumnRowGroupChangedEvent
      | ColumnVisibleEvent
      | ColumnPinnedEvent
  ) => void,
  toolPanelFunc: (event: ToolPanelVisibleChangedEvent) => void
) {
  return {
    onDragStopped: (e: DragStoppedEvent) => func(e),
    onColumnResized: (e: ColumnResizedEvent) => func(e),
    onColumnRowGroupChanged: (e: ColumnRowGroupChangedEvent) => func(e),
    onColumnVisible: (e: ColumnVisibleEvent) => func(e),
    onColumnPinned: (e: ColumnPinnedEvent) => func(e),
    onToolPanelVisibleChanged: (e: ToolPanelVisibleChangedEvent) =>
      toolPanelFunc(e),
  };
}
